<template>
    <div class="control-panel" :style="{background: background}">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            background: {
                type: String,
                default: 'white'
            }
        }
    }
</script>

<style scoped>
    .control-panel {
        background: #ffffff;
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: space-around;
        height: 44px;
        align-items: center;
    }

    @media (min-width: 768px) {
        .control-panel {
            margin-top: 15px;
        }
    }

    @media (max-width: 768px) {
        .control-panel {
            position: fixed;
            bottom: 0;
            left: 0;
        }

        @media (max-height: 350px) {
            .control-panel {
                display: none;
                /*position: static;*/
            }
        }
    }
</style>

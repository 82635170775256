<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body class='mb-1 text-center' v-if='workPosition'>
            <b-card-header header-tag="header" class="p-2">
                <h3>{{ workPosition.name }}</h3>
            </b-card-header>

            <work-positions-form v-model='workPosition' :disabled-field-key='disableFieldKey'></work-positions-form>
        </b-card>

        <control-panel>
            <b-button variant="danger" @click="$router.go( -1 )">Отмена</b-button>
            <b-button variant="warning" @click="deleteWorkPosition" v-if='isDiff'>Удалить</b-button>
            <b-button variant="success" @click="saveWorkPosition" v-if='isDiff'>Сохранить</b-button>
        </control-panel>
    </div>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep';

    import ControlPanel from '@components/_common/ControlPanel';
    import ErrorsBagList from '@components/_common/ErrorsBagList';

    import WorkPositionsForm from './WorkPositionsForm';

    import ErrorsBag from '@utils/errorsBag';
    import { WORK_POSITIONS_ENDPOINT } from '@utils/endpoints';

    const DEFAULT_WORK_POSITION = {
        order: 0,
        key: null,
        name: null
    };

    export default {
        props: [ 'id' ],
        name: "WorkPositionsEdit",
        components: { WorkPositionsForm, ControlPanel, ErrorsBagList },
        computed: {
            isDiff() {
                return this.workPosition &&
                    JSON.stringify( this.workPosition ) !== JSON.stringify( DEFAULT_WORK_POSITION );
            },
            disableFieldKey() {
                return true;
            }
        },
        data() {
            return {
                ErrorsBag,
                workPosition: null
            };
        },
        methods: {
            deleteWorkPosition() {
                ErrorsBag.discard();

                if ( confirm( 'Вы уверены, что хотите удалить этот элемент?' ) ) {
                    this.$http
                        .delete( WORK_POSITIONS_ENDPOINT + '/' + this.id )
                        .then(
                            () => this.$router.push( { name: 'WorkPositionsList' } ),
                            response => ErrorsBag.fill( response.body, false )
                        );
                }
            },
            saveWorkPosition() {
                ErrorsBag.discard();

                let params = { name: this.workPosition.name };

                this.$http
                    .put( WORK_POSITIONS_ENDPOINT + '/' + this.id, params )
                    .then(
                        response => this.workPosition = response.data.data,
                        response => ErrorsBag.fill( response.body, false )
                    );
            },
            fetchWorkPosition() {
                ErrorsBag.discard();

                this.workPosition = cloneDeep( DEFAULT_WORK_POSITION );

                this.$http
                    .get( WORK_POSITIONS_ENDPOINT + '/' + this.id )
                    .then(
                        response => this.workPosition = response.data.data,
                        response => ErrorsBag.fill( response.body, false )
                    );
            }
        },
        created() {
            this.fetchWorkPosition();
        }
    }
</script>

<template>
    <b-container fluid class='container-margin' v-if='form'>
        <b-row class='my-1 text-left row-margin'>
            <b-col md='12'>
                <label for='field_order'><b>Порядок сортировки</b></label>
                <b-form-input id='field_order' v-model='form.order' :readonly='disabledFieldOrder'></b-form-input>
            </b-col>
        </b-row>

        <b-row class='my-1 text-left row-margin'>
            <b-col md='12'>
                <label for='field_key'><b>Ключ должности</b></label>
                <b-form-input id='field_key' v-model='form.key' :readonly='disabledFieldKey'></b-form-input>
            </b-col>
        </b-row>

        <b-row class='my-1 text-left row-margin'>
            <b-col md='12'>
                <label for='field_name'><b>Наименование должности</b></label>
                <b-form-input id='field_name' v-model='form.name'></b-form-input>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: "WorkPositionsForm",
        props: {
            value: { type: Object, default: () => null },
            disabledFieldKey: { type: Boolean, default: false }
        },
        computed: {
            isDiff() {
                return JSON.stringify( this.form ) !== JSON.stringify( this.value );
            }
        },
        watch: {
            value: {
                deep: true,
                handler() {
                    if ( this.isDiff ) {
                        this.form = cloneDeep( this.value );
                    }
                }
            },
            form: {
                deep: true,
                handler() {
                    if ( this.isDiff ) {
                        this.$emit( 'input', this.form );
                    }
                }
            }
        },
        data() {
            return {
                form: null,
                disabledFieldOrder: true
            };
        }
    }
</script>

<style lang='sass' scoped>
    .row-margin
        margin-top: 10px !important

    .container-margin
        margin-bottom: 10px !important
</style>
